import { render, staticRenderFns } from "./AddressDialog.vue?vue&type=template&id=4a1bc074&scoped=true"
import script from "./AddressDialog.vue?vue&type=script&lang=js"
export * from "./AddressDialog.vue?vue&type=script&lang=js"
import style0 from "./AddressDialog.vue?vue&type=style&index=0&id=4a1bc074&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a1bc074",
  null
  
)

export default component.exports