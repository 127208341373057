
import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../utils/request";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginStatus:window.localStorage.getItem("token")?true:false,
    token:window.localStorage.getItem("token")?window.localStorage.getItem("token").replace(/"/g,""):'',
    app_url:window.localStorage.getItem("app_url") || "",
    moneyType:window.localStorage.getItem("moneyType") ? window.localStorage.getItem("moneyType") : "en",
    select:window.localStorage.getItem("select") ? window.localStorage.getItem("select") : "1",
    keyword:window.localStorage.getItem("keyword") ? window.localStorage.getItem("keyword") : "",
    addressDialog:false,
    loginDilaog:false,
    registeredDialog:false,
    forgetDialog:false,
   
   
    countryIndex:window.localStorage.getItem("countryIndex") || 0,
    country:window.localStorage.getItem("country") || "jp",
    userData: window.localStorage.getItem("userData") ? JSON.parse(window.localStorage.getItem("userData")):[],
    address_list: window.localStorage.getItem("address_list") ? JSON.parse(window.localStorage.getItem("address_list")):[],
    cartNumber:0,
    laodingAdderess:false,
    addressData:{},
    loginyfxyConten:window.localStorage.getItem("loginyfxyConten") ? JSON.parse(window.localStorage.getItem("loginyfxyConten")):"",
    loginyyszcConten:window.localStorage.getItem("loginyyszcConten") ? JSON.parse(window.localStorage.getItem("loginyyszcConten")):"",
  },
  getters: {
  },
  mutations: {

    clearDiloag(state,data){
      console.log(state)
      state.loginDilaog =false;
      state.registeredDialog =false;
      state.forgetDialog =false;
    }
  },
  actions: {
    getCart(store,data){
      if( store.state.loginStatus){
        axios({
          url:"/api/wanlshop/cart/synchro",
          method:"POST"
         
        }).then(res => {
          let num = 0;
          res.data.data.forEach(item => {
            num += item.number
          });
          store.state.cartNumber = num
          
        })
      }
     
    },
    getUserinfo(store){
      if( store.state.loginStatus){
        axios({
          url:"/api/wanlshop/user/get_userinfo",
          method:"POST"
         
        }).then(res => {
          
          store.state.userData = res.data.data;
          window.localStorage.setItem("userData",JSON.stringify(res.data.data))
         
          
        })
      }
    },
    getaddress(store){
      if( store.state.loginStatus){
        store.state.laodingAdderess = true;
        axios({
            method:"POST",
            url:"/api/wanlshop/address/getaddress"
        }).then(res => {
            store.state.address_list = res.data.data.data;

            window.localStorage.setItem("address_list",JSON.stringify(res.data.data.data))
            store.state.laodingAdderess = false;
           
        })
      }
    },
    // getLoginTiaoKuan(store){
    //   axios({
    //     method:"POST",
    //     url:"/api/index/register_init"
    //   }).then(res => {
    //     console.log(res.data.data)
    //     // store.state.loginyfxyConten = res.data.data.yfxyConten;
    //     // store.state.loginyyszcConten = res.data.data.yszcConten;
        
    //     // window.localStorage.setItem("loginyfxyConten",store.state.loginyfxyConten)
    //     // window.localStorage.setItem("loginyyszcConten",store.state.loginyyszcConten)
    //   })
    // },
    // getAuth_info(){
    //   if( store.state.loginStatus){
      
    //   }
    // }
  },
  modules: {
  }
})
